import { t } from 'i18next';
import React from 'react';
import { CounterAccentContentCard } from '../../atoms/ContentCard';
import { MainPageContent } from '../../i18n/mainPageContent';
import quill from './quill.png';
import { useDarkMode } from '../../hooks/useDarkMode';
import { ThemeOptions } from '../../contexts/DarkModeContext';

export const BlogWidget: React.FC<{}> = () => {
  const theme = useDarkMode();
  return (
    <>
      <CounterAccentContentCard style={{ justifyContent: 'center' }}>
        <h3 style={{ display: 'flex', justifyContent: 'center' }}>Blogging</h3>
        <p>{t(MainPageContent.checkOutMyBlog, { ns: ['main'] })} </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a href="https://blog.chaotically.me">Chaotically Blog</a>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={quill}
            alt="A quill"
            height={'80'}
            style={{
              filter: `${theme.theme === ThemeOptions.dark ? 'invert(1)' : 'invert(0)'}`
            }}
          ></img>
        </div>
      </CounterAccentContentCard>
    </>
  );
};

export default BlogWidget;
