import styled from 'styled-components';

// Undecided on box shadows for these:   box-shadow: 1px 1px 7px 3px rgba(0, 0, 0, 0.4);
const ContentCard = styled.div`
  ${(props) => `
  border-radius: 5px;
  border: 1px solid ${props.theme.contentCard.borderColor};
  background: ${props.theme.contentCard.background};
  margin: 5px;
  width: auto;
  box-sizing: border-box;
  padding: 10px;
  transition: transform 0.2s;
  &:hover {
     transform: scale(1.1);
  }
  `};
`;

export const TitleContentCard = styled.div`
  ${(props) => `
  border-radius: 5px;
  background: linear-gradient(to right, ${props.theme.contentCard.background} 0%, ${props.theme.contentCard.backgroundLightAccent} 100%);
  margin: 5px;
  width: auto;
  box-sizing: border-box;
  padding: 10px;
  `};
`;

export const ContactContentCard = styled.div`
  ${(props) => `
  border-radius: 5px;
  background: linear-gradient(to bottom, ${props.theme.contentCard.background} 0%, ${props.theme.contentCard.backgroundLightAccent} 100%);
  margin: 5px;
  width: auto;
  box-sizing: border-box;
  padding: 10px;
  `};
`;

export default ContentCard;

export const AccentedContentCard = styled.div`
  ${(props) => `
border-radius: 5px;
border: 1px solid ${props.theme.contentCard.borderColor};
background: radial-gradient(${props.theme.contentCard.backgroundAccent} 0%, ${props.theme.contentCard.backgroundLightAccent} 100%);
margin: 5px;
width: auto;
box-sizing: border-box;
padding: 10px;

transition: transform 0.2s;

&:hover {
  transform: scale(1.1);
}
`};
`;

export const CounterAccentContentCard = styled.div`
  ${(props) => `
border-radius: 5px;
border: 1px solid ${props.theme.contentCard.borderColor};
background: radial-gradient(${props.theme.contentCard.backgroundLightAccent} 0%, ${props.theme.contentCard.backgroundMidAccent} 100%);
margin: 5px;
width: auto;
box-sizing: border-box;
padding: 10px;
  transition: transform 0.2s;
  &:hover {
     transform: scale(1.1);
  }

`};
`;
