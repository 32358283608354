import React from 'react';
import styled from 'styled-components';
import { ThemeOptions } from '../../contexts/DarkModeContext';
import { useDarkMode } from '../../hooks/useDarkMode';
import gitHubWhite from './github-mark-white.png';
import gitHubDark from './github-mark.png';
import linkedInBlack from './linkedin-black.svg';
import linkedInWhite from './linkedin-white.svg';
import emailPlaneWhite from './paper-plane-white.svg';
import emailPlaneBlack from './paper-plane-black.svg';
import { SvgThemeToggle } from '../ThemeToggle/SvgThemeToggle';

export const SiteIcon = styled.img`
  width: 24;
  height: 24px;
  opacity: 0.6;
`;

export const ContactList = styled.ul`
  display: flex;
  padding: 1rem;
  align-items: center;
`;

export const ContactItem = styled.li`
  list-style: none;
  margin-right: 2rem;
  padding: 4px;
  transition: ease 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;

export const ContactBar: React.FC<{}> = ({ children }: any) => {
  const { theme } = useDarkMode();
  return (
    <ContactList>
      <ContactItem className="item">
        <a href="https://github.com/Ariacell">
          <SiteIcon
            src={theme === ThemeOptions.dark ? gitHubWhite : gitHubDark}
            alt="AriaGithubProfile"
            aria-label="Aria Github Profile Link"
          />
        </a>
      </ContactItem>
      <ContactItem className="logo">
        <a href="https://www.linkedin.com/in/aria-jackson-79588617b/">
          <SiteIcon
            src={theme === ThemeOptions.dark ? linkedInWhite : linkedInBlack}
            alt="AriaLinkedIn"
            aria-label="Aria LinkedIn Profile Link"
          />
        </a>
      </ContactItem>

      <ContactItem className="item">
        <a href="mailto:chaotically-me@proton.me">
          <SiteIcon
            src={theme === ThemeOptions.dark ? emailPlaneWhite : emailPlaneBlack}
            alt="AriaEmail"
            aria-label="Aria Email"
          />
        </a>
      </ContactItem>
      <ContactItem className="item">
        <SvgThemeToggle />
      </ContactItem>
    </ContactList>
  );
};
