export const lightTheme = {
  body: '#FFF',
  text: '#363537',
  toggleBorder: '#FFF',
  background: '#F7F7F7',
  backgroundGradient: 'linear-gradient(to right bottom, #F7F7F7, #F7E6E6)',
  bookWidget: {
    background: '#FFE700'
  },
  buttonGrad:
    'linear-gradient(45deg, rgba(247, 240, 240, 1) 0%, rgba(247,88,88,1)  51%, rgba(247, 240, 240, 1)  100%)',
  contentCard: {
    background: 'rgba(247, 240, 240, 1)',
    backgroundAccent: 'rgba(247,88,88,1)',
    borderColor: 'rgba(247,95,95,0.2)',
    backgroundLightAccent: 'rgba(247,100,100,1)',
    backgroundMidAccent: 'rgba(247, 240, 220, 1)'
  }
};

export const darkTheme = {
  body: '#363537',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  background: '#25274D',
  backgroundGradient: 'linear-gradient(to right bottom, #353659, #25274D)',
  bookWidget: {
    background: '#569FA7'
  },
  buttonGrad:
    'linear-gradient(45deg, rgba(50, 40, 40, 1) 0%, rgba(50, 40, 40, 0.2)  51%, rgba(50, 40, 40, 1)  100%)',
  contentCard: {
    background: 'rgba(50, 32, 32, 1)',
    borderColor: 'rgba(50, 40, 40, 0.2)',
    backgroundAccent: '#363537',
    backgroundLightAccent: 'rgba(14, 5, 8, 1)',
    backgroundMidAccent: 'rgba(50, 40, 40, 1)'
  }
};

// Light options
// https://colorhunt.co/palette/a75d5dd3756bf0997dffc3a1
// https://colorhunt.co/palette/faf8f1faeab1e5ba73c58940

//Dark options
// https://colorhunt.co/palette/1c0a00361500603601cc9544

//https://visme.co/blog/website-color-schemes/

// Bright with purple and sand accents
// {"Seasalt":"fcfafa","Black":"000000","Gray":"7d7c7a","Purple":"730071","Sandy brown":"ff9b42"}

// Dark purple with pink and blue and sand
// {"Ecru":"ddb771","Dark purple":"0c0722","Verdigris":"0cbaba","Rose Pompadour":"ef81a7","Seasalt":"f8f8f8"}
