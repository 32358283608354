import { t } from 'i18next';
import { CounterAccentContentCard } from '../../atoms/ContentCard';
import { MainPageContent } from '../../i18n/mainPageContent';

export const ProjectsWidget: React.FC<{}> = () => {
  return (
    <>
      <CounterAccentContentCard style={{ padding: '10px', textAlign: 'center' }}>
        <h3>{t(MainPageContent.recentProjectContributions, { ns: ['main'] })} </h3>

        <div>NSW Digital Birth Certificate Mobile App</div>
        <div>Path of Building Go rewrite effort</div>
        <div>Guild Wars 2 competitive logs aggregator</div>
        <div>Rust leveling helper for Path of Exile</div>
      </CounterAccentContentCard>
    </>
  );
};

export default ProjectsWidget;
