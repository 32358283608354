import React from 'react';
import ContentCard from '../../atoms/ContentCard';
import { MainPageContent } from '../../i18n/mainPageContent';
import BookCard from './BookCard';
import { t } from 'i18next';

export const BookWidget: React.FC<{}> = () => {
  return (
    <ContentCard>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <h3>{t(MainPageContent.currentlyReading, { ns: ['main'] })}</h3>
        <BookCard />
      </div>
    </ContentCard>
  );
};
