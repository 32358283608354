import styled from 'styled-components';
import ContentCard from '../../atoms/ContentCard';
import dotnet from './dotnet-icon.png';
import godot from './godot-icon.png';
import reaper from './reaper-icon.png';
import { MainPageContent } from '../../i18n/mainPageContent';
import { t } from 'i18next';

const ActivityTopicsContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const ActivityTopic = styled.div`
  ${(props) => `
border-radius: 20px;
width: 33%;
box-sizing: border-box;
padding: 10px;
text-align: center;

transition: all ease 0.2s;

& img {
    height: 90px;
    border-radius: 20px;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)
}

:hover, :active {
   transform: scale(1.1);
}

@media (max-width: 500px) {
    width: 100%;
  }

`};
`;

const ActivityTopicOne = styled(ActivityTopic)`
  transform: rotate(-7deg);
`;

const ActivityTopicTwo = styled(ActivityTopic)`
  z-index: 2;
`;

const ActivityTopicThree = styled(ActivityTopic)`
  transform: rotate(6deg);
`;

const CurrentActivityWidget: React.FC<{}> = () => {
  return (
    <>
      <ContentCard>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <h3>{t(MainPageContent.currentlyWorkingTitle, { ns: ['main'] })}</h3>
        </div>
        <ActivityTopicsContainer>
          <ActivityTopicOne>
            <img src={dotnet} alt="Dotnet C# logo" />
          </ActivityTopicOne>
          <ActivityTopicTwo>
            <img src={godot} alt="Godot Game Engine Robot Logo" />
          </ActivityTopicTwo>
          <ActivityTopicThree>
            <img src={reaper} alt="Reaper Music DAW software logo" />
          </ActivityTopicThree>
        </ActivityTopicsContainer>
      </ContentCard>
    </>
  );
};

export default CurrentActivityWidget;
