// @ts-nocheck
// todo: figure out typing for styled components globalstyles
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    /* This transition doesn't work yet because I can't get css properties into globalStyles*/
    /*background: ${({ theme }) => theme.backgroundGradient};*/
    color: ${({ theme }) => theme.text};
    font-family: Tahoma, Helvetica, Arial, Roboto, sans-serif;

    transition: all 0.50s linear;
  }


/* CSS */
button {
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 2px black;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: ${({ theme }) => theme.buttonGrad};
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

button:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

button:active {
  transform: scale(0.95);
}

  `;
// Transition causes routes to flash white due to body loading delay
